import clsx from 'clsx';

import type { SanityThreeUp } from '#/lib/sanity/queries/sections';
import { cn } from '#/lib/utils';

import { Headline } from './Headline';
import { Media } from './Media';

export function ThreeUp(props: SanityThreeUp) {
  return (
    <div
      className={clsx('p-main relative', {
        'pt-40 m:pt-100': props.background,
        'pb-40 m:pb-100': props.background && !props.media?.url,
      })}
      style={{ color: props.foreground ?? undefined }}
    >
      {props.background ? (
        <div
          className={clsx('absolute inset-x-0 top-0', {
            'bottom-65 m:bottom-165': props.media?.url,
            'bottom-0': !props.media?.url,
            'rounded-20 m:rounded-35': props.hasRoundCorners,
          })}
          style={{ background: props.background }}
        ></div>
      ) : null}
      <div className="relative">
        <div className="mx-auto max-w-[980px]">
          <Headline
            value={props.headline}
            variant={3}
            className="mx-auto mb-30 max-w-[820px] text-center m:mb-50"
          />
          <div className="mt-50 flex flex-col gap-30 px-30 m:flex-row m:px-0 l:mt-70">
            {props.details.map((detail, i) => (
              <div
                key={detail._key}
                className="three-up-detail flex-1"
                style={{ transitionDelay: `${0.3 + i * 0.1}s` }}
              >
                <div className="max-w-[375px] m:max-w-[260px] mx-auto">
                  {detail.icon ? (
                    <Media
                      media={detail.icon}
                      className={cn('mb-15', {
                        'size-35': !detail.iconSize,
                      })}
                      style={{
                        width: detail.iconSize ?? undefined,
                        height: detail.iconSize ?? undefined,
                      }}
                      mediaClassName="object-contain"
                      sizes="35px"
                    />
                  ) : null}
                  <h3 className="text-body mb-10 !font-[440] !text-charcoal">
                    {detail.title}
                  </h3>
                  <p className="text-body">{detail.caption}</p>
                </div>
              </div>
            ))}
          </div>
          {props.media?.url ? (
            <Media
              media={props.media}
              className="media mt-70 overflow-clip rounded-15 m:rounded-25"
              style={{
                transitionDelay: `${0.3 + 0.1 * props.details.length}s`,
              }}
              sizes={['100vw', { l: '980px' }]}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
